import { useQuery } from '@tanstack/react-query'
import createFetch from 'api/fetchApi'
import { salesForceBaseUrl } from 'api/api-url'
import { useAuth } from 'features/auth'

const fetchApi = createFetch(salesForceBaseUrl)

async function getUsersContact({ queryKey }) {
	const cognitoId = queryKey[0]
	const isAdmin = queryKey[1]

	if (isAdmin) {
		return null
	}

	let response

	try {
		response = await fetchApi(`/user_identity/${cognitoId}`)
	} catch (error) {
		console.error(error)
		if (error.status === 404) {
			console.log('no contact found for user')
			throw new Error('Users account is not linked to a contact')
		}
	}

	const contact = response[0]

	// Found contact but does not have membership to any accounts
	if (!contact.Account_Contact_Assignments__r) {
		return {
			...contact,
			accountContacts: []
		}
	}

	contact.accountContacts = contact.Account_Contact_Assignments__r.records
	return contact
}

export default function useUsersContact(_filters, suspense = true) {
	const { auth } = useAuth()
	return useQuery({
		queryKey: [auth?.user.username, auth.isAdmin],
		queryFn: getUsersContact,
		suspense: suspense,
		retry: false
	})
}
