import { getJwtToken } from 'features/auth'

function makeQueryParams(parameterObject) {
	if (!parameterObject) {
		return ''
	}

	const params = new URLSearchParams()

	Object.entries(parameterObject).forEach(([key, value]) => {
		if (Array.isArray(value)) {
			value.forEach((value) => params.append(key, value.toString()))
		} else if (value !== null && value !== '' && value !== undefined) {
			params.append(key, value.toString())
		}
	})

	return `?${params.toString()}`
}

function createFetch(DOMAIN) {
	return async function fetchApi(path, options = {}, expectedResponseType) {
		const { queryParams, timeout = 15000, ...restOptions } = options

		options = restOptions

		const params = makeQueryParams(queryParams)
		const url = `${DOMAIN}${path}${params}`

		// Create an AbortController to handle the timeout
		const controller = new AbortController()
		const timeoutId = setTimeout(() => controller.abort(), timeout)

		try {
			const jwtToken = await getJwtToken()

			options.headers = jwtToken
				? {
						Authorization: `Bearer ${jwtToken}`,
						'Content-Type': 'application/json'
					}
				: {}

			// Add the signal from the controller to the fetch request
			const response = await fetch(url, { ...options, signal: controller.signal })

			// Clear the timeout once the fetch request completes
			clearTimeout(timeoutId)

			if (response.status === 204) {
				return 'ok'
			}

			if (expectedResponseType) {
				return response
			}

			const data = await response.json()

			if (!response.ok) {
				if (data) {
					data.status = response.status
					throw data
				}

				let error = 'Network response was not ok'

				if (data.error_message) {
					error = data.error_message
				}

				if (data.errors) {
					error = data.errors
				}

				error = response.statusText

				throw new Error(error)
			}

			return data
		} catch (error) {
			// Handle abort error (timeout)
			if (error.name === 'AbortError') {
				throw new Error('Request timed out')
			}

			// Re-throw other errors
			throw error
		}
	}
}

export default createFetch
